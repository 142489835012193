import {
  section,
  container,
  cardTitle,
  mlAuto,
  mrAuto,
  grayColor,
  coloredShadow,
  gridListTitleBar,
  gridList,
  gridListWrapper,
  secondaryColor,
  gridItem,
  gridContainer,
  main,
  mainRaised,
  textCenter,
  whiteColor,
  title,
} from '../materialKitBase';

import { createStyles } from '@material-ui/core/styles';
import { footerStyle } from '../../../components/Footer/FooterStyle';
import { rotatingCardStyle } from '../rotatingCards';

export const indexStyle = createStyles({
  ...rotatingCardStyle,
  ...footerStyle,
  title: {
    ...title,
    color: whiteColor,
    fontSize: '1.5rem',
  },
  brand: {
    color: whiteColor,
    textAlign: 'center',
    '& h1': {
      fontSize: '4.2rem',
      fontWeight: '600',
      lineHeight: 'normal',
      display: 'inline-block',
      position: 'relative',
      margin: '5px 0',
    },
  },
  main,
  mainRaised,
  gridItem,
  gridContainer,
  gridListWrapper,
  gridList,
  gridListTitleBar,
  gridListTitleBarTitle: {
    color: secondaryColor[0],
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  gridListImage: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  // buttonGroupStyle.buttonsGroupWrapper,
  checkRoot: {
    padding: '14px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  coloredShadow,
  mlAuto,
  mrAuto,
  textCenter,
  cardTitle: {
    ...cardTitle,
    textAlign: 'center',
    marginBottom: '0px !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  cardDescription: {
    color: grayColor[0],
    textAlign: 'center',
    minHeight: '80px',
  },
  container: {
    ...container,
  },
  description: {
    color: grayColor[0],
  },
  section: {
    ...section,
    padding: '20px 0px',
  },
  pullRight: {
    float: 'right',
  },
  cardHeaderImage: {
    position: 'relative',
    padding: '0',
    zIndex: 1,
    marginLeft: '15px',
    marginRight: '15px',
    marginTop: '-30px',
    borderRadius: '6px',
    '& img': {
      width: '100%',
      borderRadius: '6px',
      pointerEvents: 'none',
    },
    '& a': {
      display: 'block',
    },
  },
  parallax: {
    height: '70vh',
    overflow: 'hidden',
    // height: '380px',
    // backgroundPosition: 'top center',
  },
  textMuted: {
    color: grayColor[4],
    fontSize: '0.725rem',
    fontWeight: 'normal',
  },
  textBySupplier: {
    color: grayColor[1],
    fontWeight: 'normal',
    fontSize: '0.725rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    // float: 'right',
  },
});
