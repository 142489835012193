import React, { Fragment, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Link, Paper, Typography } from '@material-ui/core';
import { Card } from '../components/Card/Card';
import { CardHeader } from '../components/Card/CardHeader';
import { CardBody } from '../components/Card/CardBody';
import classNames from 'classnames';
import { indexStyle } from '../assets/jss/pages/indexStyle';
import { Parallax } from '../components/Parallax/Parallax';
import { DefaultHeader } from '../components/Header/Header';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { graphql, PageRendererProps, Link as GatsbyLink } from 'gatsby';
import { CategoryNode, ProductNode } from '../interfaces/contentful';
import { GatsbyImage } from 'gatsby-plugin-image';
import { AddToCartButton } from '../components/Button/AddToCartButton';
import { Price } from '../components/Price/Price';
import { createCategoryTree, isCategorySlugAllowed } from '../helpers/treeHelper';
import backgroundImage from '../assets/img/bg2.jpg';
import { normaliseProductPayload } from '../helpers/productsHelper';
import { unstable_batchedUpdates } from 'react-dom';
import { Clear } from '@material-ui/icons';
// import { Button } from '../components/Button/Button';

import { buttonStyle } from '../components/Button/ButtonStyle';

const useStyles = makeStyles(indexStyle);
const useButtonStyles = makeStyles(buttonStyle);

export const bioQuery = graphql`
  query {
    bioProducts: allContentfulRegionalProduct(
      filter: {
        tags: { elemMatch: { slug: { eq: "biologisch" } } }
        notAvailableReason: { eq: null }
        invisible: { ne: true }
      }
    ) {
      edges {
        node {
          contentful_id
          slug
          node_locale
          title
          price
          unit
          unitValue
          createdAt
          coverImage {
            gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
          }
          suppliedBy {
            name
            slug
            location {
              lat
              lon
            }
          }
          category {
            title
            slug
          }
          notAvailableReason
          invisible
          tags {
            slug
            title
          }
        }
      }
    }
    categories: allContentfulCategory {
      edges {
        node {
          slug
          title
          contentful_id
          parentCategory {
            slug
            contentful_id
          }
          regionalproduct {
            contentful_id
          }
        }
      }
    }
  }
`;

// TODO: check more the logo rules https://agriculture.ec.europa.eu/farming/organic-farming/organic-logo_en
const BioPage: React.FC<BioPageProps> = ({ data }) => {
  const { bioProducts, categories } = data;
  const normalisedBioProducts = normaliseProductPayload(bioProducts);

  const [normalisedFilteredBioProducts, setNormalisedFilteredBioProducts] = useState(normalisedBioProducts);
  const [byCategoryFilter, setByCategoryFilter] = useState('');

  const availableCategoriesFromProducts = normalisedBioProducts.map((node) => node.category.slug);
  const uniqueAvailableCategories = [...new Set(availableCategoriesFromProducts)];

  const categoryTree = createCategoryTree(categories);

  let firstHashParam = null;
  if (typeof window !== `undefined`) {
    firstHashParam = window.location.hash.split('#')[1];
  }

  if (firstHashParam) {
    const [cat, val] = firstHashParam.split('=');
    // console.log(cat, val);

    if (cat === 'c' && val !== byCategoryFilter) {
      if (isCategorySlugAllowed(val, categories)) {
        // console.log(`${val} is allowed`);
        const test = [...normalisedBioProducts.filter((nfBioProduct) => nfBioProduct.category.slug === val)];

        const batchUpdate = () =>
          unstable_batchedUpdates(() => {
            setByCategoryFilter(val);
            setNormalisedFilteredBioProducts(test);
          });

        batchUpdate();
      } else {
        // console.log(`${val} is NOT allowed`);
      }
    }
  }

  const classes = useStyles();
  const buttonClasses = useButtonStyles();

  const intl = useIntl();

  return (
    <Fragment>
      <Helmet>
        <title>{intl.formatMessage({ id: 'main.parallax.title' })}</title>
      </Helmet>
      <DefaultHeader categoryTree={categoryTree} />

      <Parallax image={backgroundImage} className={classes.parallax}>
        <div className={classes.container}>
          <div className={classes.brand}>
            <h1>
              <FormattedMessage id="main.parallax.title" defaultMessage="Regional products" />
            </h1>
            <h2 className={classes.title}>
              <FormattedMessage
                id="main.parallax.subtitle"
                defaultMessage="Reduce the carbon footprint and shorten the food chain"
              />
            </h2>
          </div>
        </div>
      </Parallax>
      <div
        className={classNames(classes.section, classes.main, classes.mainRaised)}
        style={{
          marginBottom: '20px',
          background: 'linear-gradient(84deg, rgba(165,214,167,1) 35%, rgba(134,181,114,0.9780505952380952) 92%)',
        }}
      >
        <div className={classes.container}>
          <Typography variant="h5" component="h2" gutterBottom>
            <FormattedMessage id="bio.organicProducts" defaultMessage="Organic products" />
            :&nbsp;
            <FormattedMessage id="bio.filterByCategory" defaultMessage="Filter by category" />
            <a
              style={{ paddingLeft: '10px', paddingRight: '16px' }}
              className={classNames(buttonClasses.button, buttonClasses.warning)}
              href="/bio"
            >
              <Clear />
              Clear
            </a>
          </Typography>

          <Grid container justify="flex-start" alignItems="center" spacing={0}>
            {uniqueAvailableCategories.map((availableCategory) => {
              return (
                <Grid item key={availableCategory} md={2} sm={4} xs={6}>
                  <Link
                    style={{ display: 'flex', fontWeight: 700 }}
                    className={classNames(buttonClasses.button, buttonClasses.success)}
                    key={availableCategory}
                    component={GatsbyLink}
                    to={`#c=${availableCategory}`}
                  >
                    {availableCategory}
                  </Link>
                </Grid>
              );
            })}
          </Grid>

          <br />

          <Grid container justify="center" spacing={2}>
            {normalisedFilteredBioProducts.map((bioProductNode) => {
              return (
                <Grid item key={bioProductNode.contentful_id} md={3} sm={6} xs={10}>
                  <Card product>
                    <CardHeader image plain style={{ textAlign: 'center', padding: '10px 10px 0 10px' }}>
                      <GatsbyLink to={`/products/${bioProductNode.slug}`}>
                        <GatsbyImage alt={bioProductNode.slug} image={bioProductNode.coverImage.gatsbyImageData} />
                      </GatsbyLink>
                    </CardHeader>
                    <CardBody className={classes.textCenter} plain style={{ paddingTop: 0 }}>
                      <h4 className={classes.cardTitle} style={{ minHeight: '30px' }}>
                        <GatsbyLink style={{ fontSize: '1rem' }} to={`/products/${bioProductNode.slug}`}>
                          {bioProductNode.title}
                        </GatsbyLink>
                      </h4>
                      <div style={{ paddingTop: '0', paddingBottom: '10px' }}>
                        <FormattedMessage id="breadcrumbs.category" defaultMessage="Category" />:{' '}
                        <Link component={GatsbyLink} to={`#c=${bioProductNode.category.slug}`}>
                          {bioProductNode.category.title}
                        </Link>
                        <br />
                        <FormattedMessage id="general.suppliedBy" defaultMessage="Supplied by" />:{' '}
                        <i>{bioProductNode.suppliedBy.name}</i>
                      </div>
                      <Price product={bioProductNode} />
                      &nbsp;/&nbsp;
                      {`${bioProductNode.unitValue} ${bioProductNode.unit}`}
                      <br />
                      <AddToCartButton product={bioProductNode} quantity={1} onlyIcon={true} />
                    </CardBody>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    </Fragment>
  );
};

export default BioPage;

interface BioPageProps extends PageRendererProps {
  data: {
    bioProducts: {
      edges: { node: ProductNode }[];
    };
    categories: {
      edges: { node: CategoryNode }[];
    };
  };
}
